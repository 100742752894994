@import 'components/mixins.scss';

.esm-progress {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.tada {
  -webkit-animation: tada 1.5s ease infinite;
  animation: tada 1.5s ease infinite;
  display: inline-block;
}
label {
  margin-bottom: 0;
}
@-webkit-keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    -webkit-transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
    transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
    transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -10deg);
    transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -10deg);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    -webkit-transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
    transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
    transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.ant-popover {
  z-index: 1051 !important;
}

.ant-notification {
  z-index: 1052 !important;
}

// dark theme
[data-kit-theme='dark'] {
  .ant-table-column-sorter-up, .ant-table-column-sorter-down {
    color: #353546;
  }
  .ant-table {
    tbody {
      tr:hover {
        background: linear-gradient(to right, #1d1d26 0%,#383847 100%);
        border-color: #3c3c4d;
        box-shadow: 0 0 3px 0 rgba(255, 255, 255,0.2);
        box-sizing: border-box;
        //box-shadow: rgba(189, 158, 158,0.3) 0 2px 10px;
        transform: scale(1.003);
      }
    }
  }

  .team-logo-uploader {
    position: relative;
    .ant-upload-select-picture-card {
      background-color: transparent;
      width: 100%;
      height: auto;
      margin-right: 0 !important;
      margin-bottom: 0 !important;
    }
  }
  .user-avatar-uploader{
    position: relative;
    .ant-upload-select-picture-card {
      background-color: transparent;
      width: 100%;
      height: auto;
      margin-right: 0 !important;
      margin-bottom: 0 !important;
    }
  }
  .user-banner-uploader {
    width: 100%;
    position: absolute;
    height: 100%;
    .ant-upload-select-picture-card {
      background-color: transparent;
      width: 100%;
      height: 100%;

      .ant-upload {
        background: transparent;
      }
    }
  }
  .team-banner-uploader {
    width: 100%;
    position: absolute;
    height: 100%;
    z-index: 1;
    .ant-upload-select-picture-card {
      background-color: transparent;
      width: 100%;
      height: 279px;

      .ant-upload {
        background: transparent;
      }
    }
  }
  .cover-banner-uploader {
    width: 100%;
    position: absolute;
    height: 100%;
    z-index: 1;
    .ant-upload-select-picture-card {
      background-color: transparent;
      width: 100%;
      height: 279px;

      .ant-upload {
        background: transparent;
      }
    }
  }
  .ant-picker-panel {
    .ant-picker-content {
      height: auto;
    }
    .ant-picker-time-panel-column {
      &:after {
        height: auto !important;
      }
    }
  }
  overflow-x: auto;
  scrollbar-color:  #252337 transparent;

  ::-webkit-scrollbar {
    width: 6.5px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: #252337;
    border-right: none;
    border-radius: 8px;
  }

  // LADDER STYLES
  .ladder-tabs {
    overflow: inherit;
    .ant-tabs-nav .ant-tabs-tab {
      margin-right: 0 !important;

    }
  }

  // USER PROFILE
  .edit-profile {
    .ant-collapse-content-box {
      padding: 0;
    }
  }
}

.esm-box {
  display:inline-block;
  margin-right:2em;
  padding:1em 1.5em;
  box-shadow: -2px -2px 8px 1px rgba(161,161,201,0.2),
  2px 2px 8px 1px rgba(0,0,0,0.8);
  border-radius:5px;
  transition: all 0.3s ease-in-out;
  cursor:pointer;

  &:hover, &.active{
    color: black;
    background-color: $primary;
    box-shadow:
      2px 2px 8px 1px rgba(158, 122, 0, 0.8),
      inset -1px -1px 5px 1px rgba(255, 255, 255,0.2),
      inset 2px 2px 8px 1px rgba(167, 139, 3, 0.8);

  }
}
.icon-box-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 300px;
}
.icon-animation-box {
  fill: #FFB430;
  color: #FFB430;
  border: 3px solid #FFB430;
  display: inline-block;
  line-height: 1;
  transition: all .3s;
  font-size: 50px;
  text-align: center;
  padding: 20px;
  &:hover, &.active{
    fill: #E68700;
    color: #E68700;
    border-color: #E68700;
    animation-name: elementor-animation-bob-float,elementor-animation-bob;
    animation-duration: .3s,1.5s;
    animation-delay: 0s,.3s;
    animation-timing-function: ease-out,ease-in-out;
    animation-iteration-count: 1,infinite;
    animation-fill-mode: forwards;
    animation-direction: normal,alternate;
  }
}

@keyframes elementor-animation-bob-float {
  100% {
    transform: translateY(-8px)
  }
}
@keyframes elementor-animation-bob {
  0% {
    transform: translateY(-8px)
  }

  50% {
    transform: translateY(-4px)
  }

  100% {
    transform: translateY(-8px)
  }
}

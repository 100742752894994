// Import mixins
@import 'components/mixins.scss';

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-table-column-has-sorters {
    &:hover {
      background: $dark-gray-5 !important;
    }
  }
  .ant-table-column-sort {
    background: $dark-gray-5 !important;
  }
  .ant-table {
    .ant-table-thead {
      ::before {
        background: none !important;
      }
    }
    .ant-table-row:hover > td {
      background: transparent !important;
    }
    .ant-table-row:nth-child(2n-1) {
      background: lighten($dark-gray-4, 1%);
      &:hover {
        background: linear-gradient(to right, #1d1d26 0%, #383847 100%);
      }
    }
    .ant-table-row.top-5-ranking {
      .ant-table-cell {
        border-top: 15px solid #151322;
      }
    }
  }
}
